// Copyright (C) https://meteosource.com/static/files/colormaps.zip

export const windSpeedColors = {
    '0': '#615c7aff',
    '1': '#605d8dff',
    '2': '#5e5ea0ff',
    '3': '#5463acff',
    '4': '#4b68b7ff',
    '5': '#4679b6ff',
    '6': '#428ab4ff',
    '7': '#4899a6ff',
    '8': '#4ea897ff',
    '9': '#50b07aff',
    '10': '#51b75eff',
    '11': '#58bc56ff',
    '12': '#5ec14fff',
    '13': '#78c64dff',
    '14': '#92cb4bff',
    '15': '#a4cc48ff',
    '16': '#b6cd44ff',
    '17': '#c7ce41ff',
    '18': '#d9cf3eff',
    '19': '#d9c340ff',
    '20': '#d8b842ff',
    '21': '#d8ac43ff',
    '22': '#d8a045ff',
    '23': '#d6924aff',
    '24': '#d4844fff',
    '25': '#d37554ff',
    '26': '#d16759ff',
    '27': '#c75b5bff',
    '28': '#bd4f5cff',
    '29': '#b3435eff',
    '30': '#a93760ff',
    '31': '#9f305aff',
    '32': '#952a53ff',
    '33': '#8b244cff',
    '34': '#811d46ff',
    '35': '#72183bff',
    '36': '#621430ff',
    '37': '#530f24ff',
    '38': '#440a19ff',
    '39': '#3e0813ff',
    '40': '#38050dff',
    '41': '#310207ff',
    '42': '#2b0001ff',
};

export const temperatureColors = {
    '-30': '#ffffff9d',
    '-29': '#f6f6fa9d',
    '-28': '#eeedf49d',
    '-27': '#e5e5ef9d',
    '-26': '#dddce99d',
    '-25': '#d4d3e49d',
    '-24': '#cac1e09d',
    '-23': '#c0afdb9d',
    '-22': '#b59cd79d',
    '-21': '#ab8ad29d',
    '-20': '#a178ce9d',
    '-19': '#9766ca9d',
    '-18': '#8d53c59d',
    '-17': '#8241c19d',
    '-16': '#782ebc9d',
    '-15': '#6e1cb89d',
    '-14': '#7a42c69d',
    '-13': '#8668d39d',
    '-12': '#928fe19d',
    '-11': '#9eb5ee9d',
    '-10': '#aadbfc9d',
    '-9': '#8cb4e79d',
    '-8': '#6e8dd29d',
    '-7': '#5167be9d',
    '-6': '#3340a99d',
    '-5': '#1519949d',
    '-4': '#1442a49d',
    '-3': '#126bb59d',
    '-2': '#1194c59d',
    '-1': '#0fbdd69d',
    '0': '#0ee6e69d',
    '1': '#0fe3c79d',
    '2': '#0fe1a89d',
    '3': '#10de889d',
    '4': '#10dc699d',
    '5': '#11d94a9d',
    '6': '#3be1669d',
    '7': '#65e8819d',
    '8': '#8ff09d9d',
    '9': '#b9f7b89d',
    '10': '#e3ffd49d',
    '11': '#e5fbab9d',
    '12': '#e7f8829d',
    '13': '#e9f4599d',
    '14': '#ebf1309d',
    '15': '#eded079d',
    '16': '#f0df099d',
    '17': '#f3d20a9d',
    '18': '#f6c40c9d',
    '19': '#f9b70d9d',
    '20': '#fca90f9d',
    '21': '#ee89109d',
    '22': '#e169119d',
    '23': '#d349119d',
    '24': '#c629129d',
    '25': '#b809139d',
    '26': '#c226389d',
    '27': '#cb425d9d',
    '28': '#d55f819d',
    '29': '#de7ba69d',
    '30': '#e898cb9d',
    '31': '#e9a9c59d',
    '32': '#eababf9d',
    '33': '#ecccba9d',
    '34': '#edddb49d',
    '35': '#eeeeae9d',
    '36': '#f1f1be9d',
    '37': '#f5f5ce9d',
    '38': '#f8f8df9d',
    '39': '#fcfcef9d',
    '40': '#ffffff9d',
};